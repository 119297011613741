import { Stack, Typography } from "@mui/material";
import { StyledElement, StyledLink } from "../../../Styles/GlobalStyles";

export const SignUpFooter = () => {
  return (
    <Stack py={2} direction={"row"} justifyContent={"center"}>
      <Typography fontSize={"14px"} variant="inherit" fontWeight={600}>
        Already have an online account? &nbsp;
      </Typography>
      <StyledLink to="/login">
        <StyledElement fontSize={"14px"} fontWeight={600}>
          LOG IN
        </StyledElement>
      </StyledLink>
    </Stack>
  );
};
