import { useNavigate } from "react-router-dom";
import { AccordionDetails, Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MANAGE_ORDER, TICKET_DETAILS } from "../../../../Constants/constants";
import { setIsOrder } from "../../../../Redux/Slices/OrderDetailsSlice";
import {
  AccountDivider,
  HeadingSmall,
  HeadingTypography,
  StyledA,
  StyledElement,
  StyledGridItem,
  VerticalDivider,
} from "../../../../Styles/GlobalStyles";
import { getFlags, groupItemsInPairs, splitDataBasedOnTitle } from "../../../../Services/helper";

export const ManageTicket = (props: any) => {
  const { data, title } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state: any) => state.customerInfoDetails);
  const { troubleTicketDetails } = useSelector((state: any) => state.troubleTicketDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_WHERE_IS_MY_TECH = getFlags("enable_where_is_my_tech", envFlags);
  const appointment = troubleTicketDetails?.tickets?.[0]?.appointment;
  const isOrderAppointment =
    orderDetails?.installationAppointment && orderDetails?.installationAppointmentEndDate;

  const handleNavigate = () => {
    navigate("/home/reschedule-appointment");
    title === orderDetails?.headerTitle ? dispatch(setIsOrder(true)) : dispatch(setIsOrder(false));
  };

  const [group1, group2] = splitDataBasedOnTitle(data, title, orderDetails?.headerTitle);

  const GridRow = ({ data, index1, index2 }: { data: any; index1: number; index2: number }) => {
    const hasContent = data[index1]?.value || data[index2]?.value;

    if (!hasContent) return null;

    return (
      <Grid container spacing={2} mx={1} alignItems="stretch" display="flex">
        {data[index1]?.value && (
          <StyledGridItem item xs={6}>
            <AccordionDetails sx={{ flex: 1 }}>
              <HeadingTypography>
                {data[index1]?.name} {data[index1]?.description && data[index1]?.value}
              </HeadingTypography>
              <HeadingSmall>
                {data[index1]?.description ? data[index1]?.description : data[index1]?.value}
              </HeadingSmall>
            </AccordionDetails>
            {data[index2]?.value && <VerticalDivider orientation="vertical" />}
          </StyledGridItem>
        )}

        {data[index2]?.value && (
          <StyledGridItem item xs={6}>
            <AccordionDetails sx={{ flex: 1 }}>
              <HeadingTypography>
                {data[index2]?.name} {data[index2]?.description && data[index2]?.value}
              </HeadingTypography>
              <HeadingSmall>
                {data[index2]?.description ? data[index2]?.description : data[index2]?.value}
              </HeadingSmall>
            </AccordionDetails>
          </StyledGridItem>
        )}

        <Box ml={2} sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
          <AccountDivider sx={{ width: "94%" }} />
        </Box>
      </Grid>
    );
  };

  const showRescheduleButton =
    (title === orderDetails?.headerTitle && isOrderAppointment && orderDetails?.orderReschedule) ||
    (appointment && Object.keys(appointment).length > 0);

  const showCancelTicketButton = title === TICKET_DETAILS;

  return (
    <div>
  
      {group1?.map((item: any, index: number) => (
        <Grid container mx={1} my={1} direction="column" key={index}>
          {item?.value && (
            <>
              <AccordionDetails>
                <HeadingTypography>
                  {item?.name} {item?.description && item?.value}
                </HeadingTypography>
                <HeadingSmall>{item?.description ? item?.description : item?.value}</HeadingSmall>
              </AccordionDetails>
            </>
          )}
          {item?.value && (title === orderDetails?.headerTitle || index !== group1?.length - 1) && (
            <AccountDivider />
          )}
        </Grid>
      ))}

      {title === orderDetails?.headerTitle && (
        <Grid container spacing={2} direction="column" my={1}>
          <GridRow data={data} index1={2} index2={3} />
          {SHOW_WHERE_IS_MY_TECH && (
            <>
              {(data[4]?.value || data[5]?.value) && <GridRow data={data} index1={4} index2={5} />}
              {(data[6]?.value || data[7]?.value) && <GridRow data={data} index1={6} index2={7} />}
            </>
          )}
        </Grid>
      )}

      {appointment &&
        Object.keys(appointment).length > 0 &&
        title !== orderDetails?.headerTitle && (
          <Box ml={1} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <AccountDivider sx={{ width: "96%" }} />
          </Box>
        )}

      {showRescheduleButton && (
        <>
          <AccordionDetails sx={{ display: "block" }}>
            <StyledElement onClick={handleNavigate} fontWeight={600} ml={1}>
              RESCHEDULE APPOINTMENT
            </StyledElement>
          </AccordionDetails>

          {showCancelTicketButton && (
            <Box ml={1} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <AccountDivider sx={{ width: "96%" }} />
            </Box>
          )}

          {!showCancelTicketButton && title === orderDetails?.headerTitle && (
            <Box ml={1} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <AccountDivider sx={{ width: "96%" }} />
            </Box>
          )}
        </>
      )}

      {showCancelTicketButton && !showRescheduleButton && (
        <Box ml={1} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <AccountDivider sx={{ width: "96%" }} />
        </Box>
      )}

      {showCancelTicketButton && (
        <AccordionDetails sx={{ display: "block" }}>
          <StyledElement
            onClick={() => navigate("/home/cancel-appointment")}
            ml={1}
            fontWeight={600}>
            CANCEL TICKET
          </StyledElement>
        </AccordionDetails>
      )}

      {title === orderDetails?.headerTitle && (
        <AccordionDetails sx={{ display: "block" }}>
          <Typography ml={1}>
            Need to cancel your appointment? Call&nbsp;
            <StyledA href="tel:1-866-699-4759">1.866.MYZIPLY</StyledA>
          </Typography>
        </AccordionDetails>
      )}
    </div>
  );
};
