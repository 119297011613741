import "../assets/fonts/fonts.css";
import {
  Box,
  Button,
  Card,
  Stack,
  Switch,
  styled,
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  OutlinedInput,
  Drawer,
  Paper,
  InputProps,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Chip,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { createGlobalStyle } from "styled-components";
import { CustomizedButtonProps } from "../Pages/Billing/Billing";
import styledComponent from "styled-components";
import { StyledButtonProps } from "../Pages/Home/Home";
import { ElementProps } from "../Pages/Account/Components/BillingPref/BillingPref";
import { Theme } from "./GlobalTheme";
import { Link } from "react-router-dom";
import { ActiveTabProp } from "../components/Navlist/NavList";
import { LinkProps } from "../components/Header/Header";
import { SelectProps } from "../components/CreditDebitCard/CreditDebitCard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const isAuthenticated = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;

export interface StyledCardProps {
  padding?: string;
}

export interface CustomTextFieldProps {
  hasError?: boolean;
}

export interface ResetPasswordInputProps {
  hasError?: boolean;
}
export interface ProfileInputProps {
  hasError?: boolean;
}

export const GlobalStyle = createGlobalStyle`
  body {
    color: black;
    margin: 0;
    font-family: DM Sans !important,
  background: #ffffff,
  }
  
  
  h3{
    margin : 0;
  }
  h4{
    margin : 1.5rem 0
  }
`;

interface StyleBarProps {
  boxShadow?: number;
  background?: boolean;
}

export const TypographyLarge = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: 25,
  fontWeight: 600,
}));

export const StyledHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  position: "sticky",
});

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: Theme.palette.secondary.main,
  },
}));

export const StyledBar = styled(Box)<StyleBarProps>(({ theme, boxShadow, background }) => ({
  backgroundColor: "white",
  position: "sticky",
  top: 0,
  zIndex: 1,
  boxShadow: boxShadow ? "0px 3px 4px 0px rgb(193, 193, 193)" : "none",
}));

export const StyledImg = styled("span")(({ theme }) => ({
  marginRight: "32px",
  [theme.breakpoints.down("lg")]: { marginRight: "16px" },
}));

export const StyledLabel = styled("label")({
  cursor: "pointer",
});

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .Mui-checked": {
    "& + .MuiSwitch-track": {
      backgroundColor: "rgb(2,108,227)",
      opacity: "1 !important",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="rgb(2,108,227)" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "grey ",
    color: "white",
  },

  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 22" width="16"><path fill="grey" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')`,
    left: 12,
  },
}));

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});
export const StyledCard = styled(Card)<StyledCardProps>(({ theme, padding }) => ({
  padding:
    padding === "editautopay"
      ? "2.3rem 2.3rem"
      : padding === "totalbalance"
      ? "1.2rem"
      : " 1rem 1.5rem",
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  elevation: 0,
  borderRadius: 8,
  margin: "0.8rem 0 1.5rem 0",
}));

export const CreateServiceticketStyledCard = styled(Card)<StyledCardProps>(() => ({
  padding: " 1rem ",
  cursor: "pointer",
  textAlign: "center",
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  elevation: 0,
  borderRadius: 8,
}));

export const SecurityNoticeCard = styled(Card)(({ theme }) => ({
  background: Theme.palette.secondary.light,
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  color: "white",
  margin: "10px 0",
  elevation: 0,
  padding: 10,
  borderRadius: 8,
}));

export const StyledDiv = styled("div")({
  display: "flex",
  gap: "12rem",
});
export const StyledManageButton = styled(Button)({
  "&.MuiButton-root": {
    padding: "10px 50px",
    borderRadius: "25px",
  },
});
export const SkeltonDiv = styled("div")({
  margin: "30px 0px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "3rem 0.5rem",
  justifyContent: "space-between",
  alignItems: "center",
});
export const StyledB = styled("b")({
  fontSize: "2rem",
});
export const StyledSpan = styled("span")({
  display: "flex",
  alignItems: "center",
});

export const OStyledButton = styled(Button)(({ theme }) => ({
  padding: "10px 40px",
  border: "2px solid",
  borderRadius: "25px",
  "&:hover": { background: "none", border: "2px solid" },
}));
export const BOStyledButton = styled(OStyledButton)({
  width: "50%",
  whiteSpace: "nowrap",
  margin: "22px 0",
});

export const CustomCalendarHeaderRoot = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
  alignItems: "center",
  width: "100%",
});

export const MonthDisplay = styled(Box)({
  backgroundColor: Theme.palette.secondary.main,
  color: Theme.palette.customcolor.whiteColor,
  borderRadius: "16px",
  padding: "7px 17px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledCustomizedButton = styledComponent.button<CustomizedButtonProps>`
  border: none;
  background-color: ${({ isActive }) => (isActive ? "#0073f8fc" : "#eaeaead1")};
  border-radius: 3px;
  color: ${({ isActive }) => (isActive ? "white" : "#8f9092fc")};
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  width: 50%;
  flex-grow: 1; 
`;

export const StyleCButton = styled(StyledCustomizedButton)`
  padding: 15px 20px;
  width: 50%;
`;

export const CustomTextField = styled(TextField)<CustomTextFieldProps>(({ theme, hasError }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "0.4rem",
      borderColor: hasError ? `${Theme.palette.error.main}` : "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: hasError ? `${Theme.palette.error.main}` : `${Theme.palette.secondary.main}`,
    },
  },
  "& .MuiInputBase-input": {
    padding: "0.8rem",
  },
}));

export const StyledUl = styled("ul")(({ theme }) => ({
  marginLeft: "1rem",
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  rowGap: "8px",
}));

export const ListStack = styled(Stack)(({ theme }) => ({
  justifyContent: "flex-start",
  fontSize: "10px",
}));
export const CenterStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
}));

export const SpacerSmall = styled("div")(({ theme }) => ({
  marginTop: "6px",
}));

export const SpacerMedium = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

export const CustomChip = styled(Chip)(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: Theme.palette.customcolor.parrotgreen,
}));

export const MiniCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  borderRadius: 6,
  padding: "0.5rem",
  elevation: 0,
  height: "160px",
  minHeight: "160px",
}));

export const ImgSmall = styled("img")(({ theme }) => ({
  width: "40px",
  [theme.breakpoints.up("lg")]: { width: "30px" },
  cursor: "pointer",
}));

export const IconSmall = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: 40,
  },
}));

export const ColumnCenterStack = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledButton = styled(Button)<StyledButtonProps>(({ theme, background, width }) => ({
  background:
    background === "contained"
      ? theme.palette.secondary.main
      : background === "red"
      ? theme.palette.error.main
      : background === "cancel"
      ? theme.palette.customcolor.accordionborder
      : "white",
  color:
    background === "contained"
      ? "white"
      : background === "red"
      ? "white"
      : background === "cancel"
      ? theme.palette.customcolor.cancelbtntext
      : theme.palette.secondary.main,
  width: width === "small" ? "10rem" : width === "medium" ? "9rem" : "15rem",
  [theme.breakpoints.down(1290)]: { width: width === "small" && "7rem" },
  [theme.breakpoints.down("lg")]: { width: width === "small" && "10rem" },
  [theme.breakpoints.down(720)]: { width: width === "small" && "7rem" },
  [theme.breakpoints.down(300)]: { width: width === "small" ? "7rem" : "100%" },
  border:
    background === "outlined"
      ? `2px solid ${theme.palette.secondary.main}`
      : background === "cancel"
      ? `2px solid ${theme.palette.customcolor.accordionborder}`
      : "none",
  height: "3rem",
  padding: width === "small" ? "0" : "0 1rem",
  borderRadius: "40px",
  fontSize: "12px",
  fontWeight: "600",
  margin: "0.2rem 0",
  "&:hover": {
    background:
      background === "contained"
        ? theme.palette.secondary.main
        : background === "red"
        ? theme.palette.error.main
        : "transparent",
  },
}));

export const CardStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "space-between",
  boxShadow: "0px 0px 5px 0px rgb(193, 193, 193)",
  borderRadius: 6,
  margin: "0.5rem 0rem 1.5rem 0rem",
  alignSelf: "flex-start",
  padding: "1rem",
  [theme.breakpoints.up("lg")]: { padding: "1rem 2rem" },
}));

export const PageGrid = styled(Grid)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: { padding: "5rem 1rem" },
  [theme.breakpoints.up("md")]: { padding: "6rem 0rem", marginTop: "1.9rem" },
  [theme.breakpoints.between("sm", "md")]: { padding: "8rem 1rem" },
  [theme.breakpoints.between(600, 686)]: { marginTop: "40px" },
}));

export const PageNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  display: "block",
  [theme.breakpoints.up("sm")]: { display: "none" },
}));

export const TrackOrderBox = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  borderRadius: "0.5rem",
  margin: "0.9rem 0 1.5rem 0",
}));

export const StepperBox = styled(Box)(({ theme }) => ({
  borderTopRightRadius: 6,
  borderTopLeftRadius: 6,
  padding: "1rem 1.2rem",
}));

export const CardsContainer = styled(Stack)(({ theme }) => ({
  boxShadow: "0px 0px 5px 0px rgb(193, 193, 193)",
  borderRadius: "6px",
  margin: "0.9rem 0 1rem 0",
  padding: "1rem",
}));
export const TicketHistoryCardContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",
}));
export const StyledOfferCard = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: " 0.5rem",
}));

export const StyledCardBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem 0rem",
  cursor: "pointer",
}));

export const RowEndStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "flex-end",
}));

export const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#4C4C4C",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

export const HeadingSmall = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

export const SupportBox = styled(Box)(({ theme }) => ({
  borderRadius: "0.4rem",
  padding: "0.8rem 1rem",
  borderLeft: "6px solid #026ce3",
  boxShadow: "0px 0px 3px 0px rgb(193, 193, 193)",
  margin: "0.5rem 0",
}));

export const StyledAccordion = styled(ColumnCenterStack)(({ theme }) => ({
  borderBottom: `1px solid ${Theme.palette.customcolor.accordionborder}`,
  cursor: "pointer",
  backgroundColor: Theme.palette.customcolor.accordionbackground,
}));

export const SolutionsCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 0px 5px 0px rgb(193, 193, 193)",
  margin: "1rem 0 1.5rem 0",
  [theme.breakpoints.down(600)]: { margin: "0.5rem 0 1rem 0" },
  padding: "1rem ",
  [theme.breakpoints.down(600)]: { padding: "0.5rem" },
  borderRadius: "0.5rem",
}));

export const TotalBalanceStack = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  [theme.breakpoints.up(1200)]: { margin: "13px 0px" },
}));

export const IconLabelStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "0.5rem",
  alignItems: "center",
}));

export const ItalicTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: Theme.palette.customcolor.customText,
}));

export const ButtonStack = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "center",
  gap: "0.5rem",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: { flexDirection: "row", gap: "10px" },
  [theme.breakpoints.down(700)]: { marginTop: "14px" },
}));

export const CardHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.3125rem",
  [theme.breakpoints.down(600)]: { fontSize: "1.1rem" },
  color: theme.palette.customcolor.cardHeading,
}));
export const RegisterFormHeading = styled(CardHeading)(({ theme }) => ({
  fontSize: "20px !important",
}));

export const StyledDialog = styled(Dialog)(({ width }: { width?: string }) => ({
  zIndex: "2",
  "& .MuiDialog-paper": {
    padding: "1rem",
    borderRadius: "1rem",
    width,
  },
}));

export const ServiceTicketDialogBox = styled(Dialog)(({ width }: { width?: string }) => ({
  zIndex: "2",
  "& .MuiDialog-paper": {
    padding: "1rem",
    borderRadius: "0.5rem",
    width,
  },
}));

export const CalendarText = styled(TextField)(({ theme }) => ({
  width: "350px",
  "& .MuiInputAdornment-root": {
    display: "flex",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const ShowMoreStyledDialog = styled(Dialog)(({ width }: { width?: string }) => ({
  zIndex: "5",
  "& .MuiDialog-paper": {
    padding: "1rem",
    borderRadius: "1rem",
    width,
  },
}));

export const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(254,255,254,255)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
    fontSize: 12,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export const CenteredContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "60vh",
  textAlign: "center",
});

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "4rem 3rem",
  // [theme.breakpoints.up("lg")]: { padding: "2rem 10rem" },
}));

export const AddOnsCard = styled(Card)<{ alreadyAdded: boolean }>(({ theme, alreadyAdded }) => ({
  elevation: 0,
  marginBottom: 10,
  width: "49%",
  border: `1.5px solid ${alreadyAdded ? Theme.palette.secondary.main : "black"}`,
  borderRadius: 20,
  padding: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "white",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const LoginBtn = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  backgroundColor: Theme.palette.secondary.main,
  width: "60%",
  color: Theme.palette.customcolor.whiteColor,
  fontWeight: "600",
  [Theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
  "&:hover": {
    backgroundColor: Theme.palette.customcolor.emailTextColor,
  },
  "&:disabled": {
    backgroundColor: Theme.palette.customcolor.cancelbtntext,
    color: Theme.palette.customcolor.whiteColor,
  },
  padding: "12px 16px",
}));

export const StyledElement = styled(Typography)<ElementProps>(({ theme, textcolor }) => ({
  color:
    textcolor === "red"
      ? theme?.palette?.error?.main
      : textcolor === "green"
      ? theme?.palette?.primary?.main
      : textcolor === "black"
      ? theme.palette.customcolor.cardHeading
      : theme?.palette?.secondary?.main,
  cursor: "pointer",
}));

export const StyledSpanElement = styled("span")<ElementProps>(({ theme }) => ({
  color: theme?.palette?.secondary?.main,
  cursor: "pointer",
}));
export const AccountardText = styled(Typography)<ElementProps>(({ theme, textcolor }) => ({
  color:
    textcolor === "red"
      ? theme?.palette?.error?.main
      : textcolor === "green"
      ? theme?.palette?.primary?.main
      : textcolor === "black"
      ? theme.palette.customcolor.cardHeading
      : theme?.palette?.secondary?.main,
  cursor: "pointer",
  [theme.breakpoints.down(769)]: { fontSize: "14x" },
}));

export const LinkTypography = styled(StyledElement)(({ theme }) => ({
  fontSize: "16px",
  [theme.breakpoints.down(600)]: { fontSize: "14px" },
}));

export const AccountBoxGrid = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  padding: "1.4rem 1rem",
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  borderRadius: 8,
  textDecoration: "underline",
  textUnderlinePosition: "from-font",
  margin: "1rem 0 2rem 0",
}));

export const AccountBox = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "white",
  [theme.breakpoints.down(650)]: { width: "100%" },
}));

export const StyledTextFeild = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: `${Theme.palette.secondary.main}`,
    },
  },
  width: "50%",
  [theme.breakpoints.down("md")]: { width: "100%" },
  cursor: "pointer",
}));

export const PaymentTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      //  borderColor: `${Theme.palette.secondary.main}`,
    },
  },
  width: "40%",
  [theme.breakpoints.down("md")]: { width: "100%" },
  cursor: "pointer",
}));

export const MakeaPaymentDivider = styled(Divider)(({ theme }) => ({
  margin: " 16px 0px 16px 4px",
}));

export const PaymentBox = styled(Box)(({ theme }) => ({
  boxShadow: "inset 0px 0px 5px 0px rgb(251,251,251)",
  background: theme.palette.customcolor.accountbackground,
  padding: "1rem 1.2rem",
  width: "100%",
}));

export const CustomStyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "0.5rem",
  marginTop: "3rem",
  padding: " 0.5rem ",
  paddingBottom: "3rem",
}));

export const StyledTitle = styled("p")(({ theme }) => ({
  fontSize: "1.2rem",
}));

export const StyledInput = styled("input")<InputProps>(({ theme, margin, error }) => ({
  borderRadius: "0.2rem",
  fontSize: "1.2rem",
  outline: "none",
  padding: "1rem",
  margin: margin ? "0.3rem 0rem" : "1rem 0",
  width: "85%",
  border: error ? "1px solid red" : "1px solid #b4b4b5",
}));

export const VerifyText = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  textUnderlinePosition: "from-font",
  textDecorationThickness: "1px",
}));

export const ProfileTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  marginBottom: "0.5rem",
  color: theme.palette.customcolor.profileTypoGraphyColor,
}));
export const ProfileInput = styled(OutlinedInput)<ProfileInputProps>(({ theme, hasError }) => ({
  backgroundColor: "white",
  borderColor: hasError ? `${Theme.palette.error.main}` : `${Theme.palette.secondary.main}`,
  "& .MuiOutlinedInput-input": {
    padding: "0.8rem 1rem",
  },
  "& input:focus ~ .MuiOutlinedInput-notchedOutline": {
    borderColor: hasError ? `${Theme.palette.error.main}` : `${Theme.palette.secondary.main}`,
  },
  "&:not(:focus)": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: hasError ? theme.palette.error.main : "none",
    },
  },
}));
export const ProfileEmailInput = styled(OutlinedInput)<ProfileInputProps>(() => ({
  backgroundColor: "white",
  borderColor: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.8rem 0rem",
  },
}));

export const AccountCard = styled(Stack)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: 8,
  margin: "10px 0",
  padding: "6px",
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
}));

export const StepperTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  color: "white",
  [theme.breakpoints.down(350)]: { fontSize: "0.6rem" },
}));

export const ColorBar = styled("div")(({ theme }) => ({
  height: "0.5rem",
  background: theme.palette.customcolor.skyblue,
  borderRadius: "0.5rem 0.5rem 0 0",
}));

export const StyledA = styled("a")(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

export const MonthlyBillCard = styled(Stack)({
  alignItems: "center",
  padding: "1.5rem 1rem",
  marginBottom: "0.5rem",
  borderRadius: "10px",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 1px",
});

export const StyledCheckbox = styled("input")({
  width: "1rem",
  marginRight: "1rem",
});

export const CompareBillStack = styled(Stack)({
  padding: "1rem 0.1rem",
  height: "46vh",
  overflow: "auto",
  marginBottom: "0.5rem",
});
export const StyledDivBar = styled(Typography)(({ theme }) => ({
  border: `3px solid ${theme.palette.primary.main}`,
  marginTop: "18px !important",
  [theme.breakpoints.between(600, 686)]: {
    marginTop: "8px !important",
  },
  [theme.breakpoints.down(600)]: {
    marginTop: "2px",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2px !important",
  },
}));

export const NavigateLink = styled(Link)<ActiveTabProp>(({ theme, active }) => ({
  cursor: "pointer",
  textDecoration: "none",
  fontWeight: "bold",
  color: active ? theme.palette.primary.main : "black",
  height: "46px",
  padding: "0.5rem 4rem 0 0.5rem",
  [theme.breakpoints.down("lg")]: { paddingRight: "2rem" },
  [theme.breakpoints.down(1000)]: { paddingRight: "1rem" },
  [theme.breakpoints.down("md")]: { paddingRight: "2rem" },
  [theme.breakpoints.down("sm")]: { display: "none" },
  "&:hover": { color: theme.palette.primary.main },
}));

export const StyledNavLinks = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "17px",
}));

export const StyledLink = styled(Link)<LinkProps>(({ theme, active, nav }) => ({
  color: nav ? "blue" : active ? Theme.palette.primary.main : Theme.palette.customcolor.cardHeading,
  textDecoration: "none",
  fontSize: "0.8rem",
}));

export const ZiplyLogo = styled("img")(({ theme }) => ({
  width: "150px",
  marginRight: "32px",
  [theme.breakpoints.down("sm")]: { width: "100px" },
  [theme.breakpoints.down(600)]: { width: "80px" },
  [theme.breakpoints.down(1200)]: { width: "100%" },
  [theme.breakpoints.down("lg")]: { marginRight: "16px" },
}));

export const ZoomIcon = styled("img")(({ theme }) => ({
  width: "150px",
  marginRight: "24px",
  position: "absolute",
  right: "20PX",
  bottom: "25px",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
  [theme.breakpoints.down("sm")]: { width: "100px" },
  [theme.breakpoints.down(600)]: { width: "80px" },
  [theme.breakpoints.down(1200)]: { width: "100%" },
  [theme.breakpoints.down("lg")]: { marginRight: "16px" },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "24px",
  flexWrap: "wrap",
  [theme.breakpoints.up("md")]: {
    marginLeft: "60px",
  },

  [theme.breakpoints.down("md")]: {
    direction: "column",
    justifyContent: "center",
  },
}));

export const MenuBar = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { display: "none" },
}));

export const NavListStack = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2rem",
  [theme.breakpoints.down(800)]: { justifyContent: "space-around" },
  [theme.breakpoints.down(600)]: { display: "none" },
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up(600)]: { display: "none" },
  "& .MuiPaper-root": {
    width: "65%",
    padding: "1rem 0.5rem",
  },
}));
export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "1.5rem",
  backgroundColor: theme.palette.customcolor.autopaybackground,
  margin: "1rem 0",
  width: "65%",
  [theme.breakpoints.down(1400)]: { width: "80%" },
  [theme.breakpoints.down(900)]: { width: "85%" },
  [theme.breakpoints.down(650)]: { width: "98%" },
}));
export const SaveAutoPayCard = styled(Card)(({ theme }) => ({
  margin: "1rem 0",
  padding: "3rem 9rem",
  borderRadius: "15px",
}));

export const RowCenterStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
}));

export const SettingsBox = styled(Box)(({ theme }) => ({
  padding: "1rem",
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  background: "white",
  borderRadius: 8,
}));

export const SettingsTypography = styled(Typography)(({ theme }) => ({
  marginBottom: "1rem",
  fontWeight: "600",
  fontSize: "1.2rem",
}));

export const InformationBox = styled(Box)(({ theme }) => ({
  margin: "  2rem 0 0 0",
}));

export const BoxStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const CopyIcon = styled(ContentCopyIcon)(({ theme }) => ({
  fontSize: "16px",
  cursor: "pointer",
}));

export const DividerSmall = styled(Divider)(({ theme }) => ({
  display: "none",
  marginTop: "1rem",
  [theme.breakpoints.down("sm")]: { display: "block" },
}));

export const AccountStyledCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  elevation: 0,
  borderRadius: 8,
  margin: "0.5rem 0 1.5rem 0",
}));

export const AccountDivider = styled(Divider)(({ theme }) => ({
  margin: "0rem 1.2rem",
}));

export const ManageTicketDivider = styled(Divider)(({ theme }) => ({
  margin: "0rem 1.2rem",
}));

export const SwitchDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "8px",
    margin: "16px",
  },
}));

export const VerticalDivider = styled(Divider)({
  height: "50%",
  position: "absolute",
  right: 0,
  bottom: "15%",
});

export const StyledGridItem = styled(Grid)({
  display: "flex",
  alignItems: "center",
  position: "relative",
});

export const FaqAccordion = styled(MuiAccordion)(({ theme }) => ({
  "& .Mui-expanded": {
    background: theme.palette.customcolor.notificationunread,
  },
}));

export const FaqAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "0",
  padding: "0.5rem 1rem",
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const FaqAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  background: theme.palette.customcolor.notificationunread,
}));

export const StyledDivider = styled(Divider)({
  margin: "0.2rem 0 ",
});

export const BlankScreenStack = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  borderRadius: "6px",
  padding: "2rem 1rem",
}));

export const LinkAccountBox = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  borderRadius: "8px",
  margin: "1rem 0 2rem 0",
}));

export const RowStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  padding: "1rem 1.2rem",
}));

export const StyleAccordian = styled(Accordion)(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

export const StyleAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "0",
  padding: "1rem 1.2rem ",
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
}));

export const StyledSelect = styled("select")<SelectProps>(
  ({ theme, width, margin, background }) => ({
    width: width,
    borderRadius: "0.2rem",
    fontSize: "1.2rem",
    outline: "none",
    padding: "1rem 0.2rem",
    margin: margin ? "1rem 0" : "0.5rem 0rem",
    background:
      background === "grey"
        ? theme.palette.customcolor.selectbackground
        : `${Theme.palette.customcolor.whiteColor}`,
    border: `1px solid ${Theme.palette.customcolor.selectBorder}`,
  })
);

export const CardsTextField = styled(TextField)({
  background: "white",
  borderRadius: "0.2rem",
  fontSize: "1.2rem",
  outline: "none",
  margin: "0.5rem 0",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: `${Theme.palette.secondary.main}`,
    },
  },
});

export const AutoPayDivider = styled(Divider)(({ theme }) => ({
  margin: "0.4rem 0",
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.MuiRadio-root": {
    padding: "10px 0px 10px 9px",
    marginRight: "10px",
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputBase-root": {
    width: "50%",
    [theme.breakpoints.down(650)]: { width: "100%" },
  },
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: "1.5rem",
  fontSize: "0.9rem",
  [theme.breakpoints.down(600)]: { fontSize: "0.7rem" },
  fontWeight: 600,
  padding: "6px 16px",
  border: `2px solid ${Theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: Theme.palette.customcolor.whiteColor,
    borderColor: Theme.palette.customcolor.emailTextColor,
    color: Theme.palette.customcolor.emailTextColor,
  },
}));

export const ContainedButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: disabled
    ? Theme.palette.customcolor.disableSwitch
    : Theme.palette.secondary.main,
  borderRadius: "1.5rem",
  fontWeight: 600,
  fontSize: "0.9rem",
  [theme.breakpoints.down(600)]: { fontSize: "0.7rem" },
  padding: "8px 16px",
  color: Theme.palette.customcolor.whiteColor,
  "&:hover": {
    backgroundColor: Theme.palette.customcolor.emailTextColor,
  },
}));

export const StyledNotification = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  padding: "1rem",
  margin: "1rem 0",
}));

export const NotificationDate = styled(Typography)({
  textAlign: "right",
  color: Theme.palette.customcolor.profileTypoGraphyColor,
  fontSize: "0.8rem",
  lineHeight: "1",
});
export const ApiError = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "700",
  fontSize: "14px",
  margin: "4px",
  [theme.breakpoints.down("md")]: { fontSize: "12px" },
}));

export const ResetPasswordInput = styled(OutlinedInput)<ResetPasswordInputProps>(
  ({ theme, hasError }) => ({
    borderRadius: "0.4rem",
    "& .MuiInputBase-input": {
      padding: "0.8rem",
    },
    "& input:focus ~ .MuiOutlinedInput-notchedOutline": {
      borderColor: hasError ? `${Theme.palette.error.main}` : `${Theme.palette.secondary.main}`,
    },
    "&:not(:focus)": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: hasError ? theme.palette.error.main : "none",
      },
    },
  })
);

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "16px",
    [theme.breakpoints.down(600)]: { fontSize: "14px" },
  },
}));

export const BillingAccountStack = styled(Stack)(({ theme }) => ({
  backgroundColor: Theme.palette.customcolor.accordionbackground,
  borderBottom: `1px solid ${Theme.palette.customcolor.accordionborder}`,
}));

export const BillingAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingRight: "0",
  width: "80%",
  [theme.breakpoints.down(600)]: { width: "70%" },
  borderRight: `1px solid ${Theme.palette.customcolor.accordionborder}`,
}));

export const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
  marginTop: 4,
  marginLeft: 0,
  color: theme.palette.error.main,
}));

export const TruncatedTypography = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
}));

export const ErrorStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  margin: "16px 0px",
  padding: "16px 0px",
  gap: "8px",
  backgroundColor: Theme.palette.customcolor.errorBackGroundColor,
}));

export const LoginTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  marginLeft: "8px",
  display: "block",
}));

export const ErrorTypography = styled(Typography)(({ theme }) => ({
  color: Theme.palette.error.main,
}));

export const SpanTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  [theme.breakpoints.down(600)]: { fontSize: "12px" },
}));

export const PTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  [theme.breakpoints.down(600)]: { fontSize: "14px" },
}));

export const BoldSpan = styled(SpanTypography)(({ theme }) => ({
  fontWeight: 600,
}));

export const ContainerStack = styled(Stack)(({ theme }) => ({
  height: "100vh",
  justifyContent: "space-between",
  overflow: "auto",
}));

export const CustomImg = styled("img")(({ theme }) => ({
  borderRadius: 13,
  width: 250,
  height: 100,
}));

export const LoginCenterStack = styled(CenterStack)(({ theme }) => ({
  flexDirection: "column",
  [theme.breakpoints.down(600)]: { width: "100%" },
  padding: "72px 0px",
}));

export const BoldStyledElement = styled(StyledElement)(({ theme }) => ({
  fontWeight: 600,
}));

export const SignInStack = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
}));

export const LoginStyledA = styled(StyledA)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "13px",
  [theme.breakpoints.down(600)]: { fontSize: "12px" },
}));

export const AppProposalLink = styled(StyledA)(({ theme }) => ({
  fontWeight: 600,
  textDecoration: "underline",
  [theme.breakpoints.down(600)]: { fontSize: "12px" },
}));

export const AppProposalText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
    display: "block",
  },
  [theme.breakpoints.up(601)]: {
    display: "none",
  },
  textAlign: "center",
}));

export const StickyBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: isAuthenticated === "TRUE" ? "133px" : "110px",
  width: "23.4%",
  right: "10%",
  marginTop: "8px",
}));

export const DialogTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  [theme.breakpoints.down(600)]: { fontSize: "12px" },
}));

export const FooterLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  cursor: "pointer",
  color: Theme.palette.customcolor.footerTextColor,
  ":hover": {
    color: theme.palette.secondary.main,
  },
}));

export const FooterTypography = styled(Typography)(({ theme }) => ({
  color: Theme.palette.customcolor.footerTextColor,
  flexShrink: 1,
  fontSize: "16px",
  textAlign: "center",
}));

const LoginColorBar = styled(Stack)(({ theme }) => ({
  margin: "0 auto",
  width: "95%",
  height: theme.spacing(0.75),
  position: "fixed",
  borderRadius: theme.spacing(4),
  backgroundColor: "#02caff",
  bottom: 0,

  "&:after": {
    content: "''",
    display: "block",
    width: "20%",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#000050",
    height: "100%",
    borderRadius: theme.spacing(4),
  },

  "&:before": {
    content: '""',
    display: "block",
    width: "50%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#009200",
    height: "100%",
    borderRadius: theme.spacing(4),
  },
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
}));

const LoginStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(5, 0, 2, 0),
  height: "100vh",
}));

const Form = styled(Paper)(({ theme }) => ({
  padding: "2rem 0",

  maxWidth: theme.breakpoints.values.md,
  borderRadius: "1rem",
}));

const InputStack = styled(Stack)({
  width: "100%",
});

export { LoginColorBar, Wrapper, LoginStack, Form, InputStack };

export const PDFWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "2rem 0",
  padding: "1px",
}));

export const ContentSection = styled("div")(({ theme }) => ({
  overflowY: "auto",
  boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.68);",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "46rem",
}));

export const ContentScreen = styled("div")(({ theme }) => ({
  marginTop: 100,
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "#fafafa",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  height: "6rem",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.29)",
  width: "46rem",

  justifyContent: "space-between",
  alignItems: "center",
  zIndex: "1",
  padding: "1rem",
}));

export const StyledTroubleTicketOrder = styled("h4")(({ theme }) => ({
  padding: "0",
  margin: "0",
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: { fontSize: "11px" },
}));

export const StyledTroubleTicketStatus = styled(Box)(({ theme }) => ({
  padding: "0",
  margin: "0",
  display: "flex",
  justifyContent: "flex-end",
  textTransform: "uppercase",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: { fontSize: "11px" },
}));

export const StyledTroubleTicketTypography = styled(Typography)(({ theme }) => ({
  padding: "0",
  margin: "0",
  fontSize: "12px",
  [theme.breakpoints.down("sm")]: { fontSize: "11px" },
}));

export const StyledTroubleTicketStatusContainer = styled(Stack)(({ theme }) => ({
  padding: "10px 0px",
  margin: "0",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: { fontSize: "11px" },
}));
