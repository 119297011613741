import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Stack, Typography, DialogContent, IconButton } from "@mui/material";
import {
  CardHeading,
  CenterStack,
  PageGrid,
  StickyBox,
  StyledCard,
  StyledDialog,
  StyledLink,
} from "../../../../Styles/GlobalStyles";
import { AccountCard } from "../../../../components/shared/AccountCard/AccountCard";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import {
  setAppointmentCancelFailed,
  setAppointmentCancelSuccess,
} from "../../../../Redux/Slices/OrderDetailsSlice";
import { useNavigate } from "react-router-dom";
import { ConfirmationModel } from "../../../../components/shared/ConfirmationModel/ConfirmationModel";
import { ImappingData } from "../TroubleTicketStatus/TroubleTicketStatus";
import {
  CONFIRM_CANCELLATION_TEXT,
  TT_CANCELLATION_CONFIRMATION_HEADING,
} from "../../../../Constants/constants";
import { useCancelTroubleTicket } from "../../../../hooks/TroubleTicket/useCancelTroubleTicket";
import moment from "moment";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";

export const CancelAppointment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openError, setOpenError] = useState(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    containedButton: { buttonType: "", buttonTitle: "" },
    outlinedButton: { buttonType: "", buttonTitle: "" },
  });
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { appointmentCancelSuccess, appointmentCancelFailed } = useSelector(
    (state: any) => state.orderDetails
  );
  const { troubleTicketDetails, createdDate, appointment } = useSelector(
    (state: any) => state.troubleTicketDetails
  );
  const { billingAddress } = useSelector((state: any) => state.customerPlanDetails);
  const isAppointment = troubleTicketDetails?.tickets?.[0]?.appointment !== null;
  const inputData = moment(appointment?.appointmentDate).format("MMM DD");

  const mappingData: ImappingData[] = [
    {
      name: "Ticket no :",
      value: `${troubleTicketDetails?.tickets?.[0]?.ticketId}`,
    },
    {
      name: "Created Date :",
      value: createdDate,
    },
    {
      name: "Appointment Date and Time",
      value:
        isAppointment &&
        `${inputData}, ${appointment?.appointmentStartTime} - ${appointment?.appointmentEndTime}`,
    },
    {
      name: "Primary contact number",
      value: troubleTicketDetails?.tickets?.[0]?.appointment?.canBeReachedTelephoneNumber,
    },
    {
      name: "Service address :",
      value: isAppointment && billingAddress,
    },
  ];

  const { handleCancelTroubleTicket, loadingCancel } = useCancelTroubleTicket(setOpenDialog);

  const closeSuccessDialog = () => {
    dispatch(setAppointmentCancelSuccess(false));
    navigate("/home");
  };

  const closeFailureDialog = () => {
    dispatch(setAppointmentCancelFailed(false));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData({});
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogData({
      heading: CONFIRM_CANCELLATION_TEXT,
      primaryContent: TT_CANCELLATION_CONFIRMATION_HEADING,
      containedButton: { buttonType: "payment", buttonTitle: "yes" },
      outlinedButton: { buttonType: "pay", buttonTitle: "no" },
    });
  };

  return (
    <>
      <Box sx={{ marginTop: "1rem" }} minHeight={"90vh"}>
        <PageGrid container>
          <Grid item lg={8.3} xs={12}>
            <StyledLink to={"/home"}>
              <Stack alignItems={"center"} direction={"row"}>
                <ArrowBackIosIcon color="secondary" />
                <CardHeading>Cancel Ticket</CardHeading>
              </Stack>
            </StyledLink>
            <StyledCard>
              <b>Current appointment details</b>
              <Grid container direction="row" justifyContent={"space-between"} gap={2} my={2}>
                {mappingData?.map((item: any) => {
                  return (
                    <>
                      {item?.value && (
                        <Grid item xs={12} mb={{ xs: "26px", sm: "0px" }} sm={5.8}>
                          <Stack
                            alignItems={"center"}
                            direction={"row"}
                            justifyContent={{ xs: "space-between", sm: "flex-start" }}>
                            <Typography
                              component={"p"}
                              color={Theme.palette.customcolor.profileTypoGraphyColor}>
                              {item?.name}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            alignItems={"center"}
                            flexWrap={"wrap"}>
                            <Typography component={"span"} variant="subtitle2">
                              {item?.value}
                            </Typography>
                          </Stack>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </Grid>
              <br />
              <CenterStack>
                <Button onClick={handleOpenDialog} title="Cancel ticket" type="root" />
              </CenterStack>
            </StyledCard>
          </Grid>

          <Grid
            item
            xs={12}
            lg={3.5}
            display={{ xs: "block", lg: scrollPosition > 15 ? "block" : "none" }}>
            <AccountCard />
            <NeedHelpCard />
          </Grid>
        </PageGrid>
        <StickyBox display={{ xs: "none", lg: scrollPosition > 15 ? "none" : "block" }}>
          <AccountCard />
          <NeedHelpCard />
        </StickyBox>
      </Box>

      <StyledDialog data-testid="usermessage" fullWidth open={appointmentCancelSuccess}>
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={closeSuccessDialog}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack justifyContent={"center"} spacing={2} alignItems={"center"}>
            <CardHeading
              sx={{ fontSize: { xs: "14px", sm: "18px" } }}
              data-testid="success-message"
              fontWeight={600}>
              Ticket cancelled successfully
            </CardHeading>
            <Typography fontSize={{ xs: "13px", md: "16px" }} component={"p"} textAlign={"center"}>
              You have successfully cancelled your Ticket
            </Typography>
            <Box>
              <Button onClick={closeSuccessDialog} title="Done" type="root" />
            </Box>
          </Stack>
        </DialogContent>
      </StyledDialog>

      <ConfirmationModel
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogData={dialogData}
        setDialogData={setDialogData}
        onClick={handleCancelTroubleTicket}
        onClickSecondary={handleCloseDialog}
      />

      <StyledDialog data-testid="usermessage" fullWidth open={appointmentCancelFailed}>
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={closeFailureDialog}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack justifyContent={"center"} spacing={1} pb={2} alignItems={"center"}>
            <CardHeading
              pb={2}
              sx={{ fontSize: { xs: "14px", sm: "18px" } }}
              data-testid="success-message"
              fontWeight={600}>
              Cancellation Unsuccessful
            </CardHeading>
            <Typography fontSize={{ xs: "13px", md: "16px" }} component={"p"} textAlign={"center"}>
              We are not able to cancel your appointment currently.
            </Typography>
            <Typography fontSize={{ xs: "13px", md: "16px" }} component={"p"} textAlign={"center"}>
              Please try again after some time
            </Typography>
            <Stack direction={"row"} justifyContent={"center"} gap={1}>
              <Typography fontSize={{ xs: "14px", md: "16px" }}>Please </Typography>
              <ContactSupport />
              <Typography fontSize={{ xs: "14px", md: "16px" }}> for further help.</Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </StyledDialog>
      {loadingCancel && <LoadingSpinner />}
      {openError && <UpdateErrorComponent setOpenError={setOpenError} openError={openError} />}
    </>
  );
};
