import React, { useState, useEffect, useCallback } from "react";
import {
  CardHeading,
  CenterStack,
  CreateServiceticketStyledCard,
  CustomTextField,
  ErrorMessage,
  ProfileInput,
  StyledA,
  StyledDialog,
} from "../../../../Styles/GlobalStyles";
import {
  Box,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  setCreateServiceTicketFail,
  setCreateServiceTicketSuccess,
  setOpenSelectReasons,
  setOpenServiceTicketDialog,
} from "../../../../Redux/Slices/TroubleTicketSlice";
import { CREATE_SERVICE_TICKET_HEADINGS } from "../../../../Constants/constants";
import { ContactInfoVerification } from "./ContactInfoVerification";
import {
  addSpaceAfterComma,
  defaultFormatPhone,
  formatPhoneNumber,
} from "../../../../Services/helper";
import RepairAppointmentDialog from "./RepairAppointmentDialog";
import {
  CREATE_SERVICE_TICKET,
  GET_AVAILABLE_SLOTS_FOR_TT,
  GET_TROUBLE_TICKET_STATUS,
} from "../../query";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import SuccessDialogBox from "../NetworkStatus/SuccessDialogBox";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";
import useServiceTicketAPI from "./useServiceTicketAPI";
import { useNavigate } from "react-router-dom";

// Define proper type for the reason object
interface ReasonType {
  reason?: string;
  dispatchOrder?: boolean;
  isOther?: boolean;
}

function CreateServiceTicketNew({ getTroubleTicketStatus }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectReason, setSelectReason] = useState<ReasonType>({});
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [shouldFetchSlots, setShouldFetchSlots] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>(null);
  const [otherReason, setOtherReason] = useState("");
  const [otherReasonError, setOtherReasonError] = useState("");
  const [openOtherReason, setOpenOtherReason] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [AvailableSlotsError, setavailableSlotsError] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>("");
  const { serviceAddress } = useSelector((state: any) => state.customerPlanDetails);
  const { telephoneContact, accountNumber, ndsObj, givenNameFamilyName } = useSelector(
    (state: any) => state.customerInfoDetails
  );
  const {
    openOutageBanner,
    existingTicket,
    createServiceTicketFail,
    createServiceTicketSuccess,
    openServiceTicketDialog,
    detailsForTTAvailableSlots,
    reasonsForTT,
    reasonsForTTError,
    getTTDetailsError,
    openSelectReasons,
  } = useSelector((state: any) => state.troubleTicketDetails);
  const customerDetails = useSelector((state: any) => state?.customerInfoDetails);

  const formattedPhone = formatPhoneNumber(telephoneContact?.telephoneNumber);

  const initialFormData = {
    phoneNumber: formattedPhone || "",
    secondaryPhone: "",
  };

  const initialFormErrors = {
    phoneNumber: "",
    secondaryPhone: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const {
    getReasonCodesForTT,
    reasonCodesForTTLoading,
    getDetailsForAvailableSlots,
    getAvailableSlotsDetailsLoading,
  } = useServiceTicketAPI(accountNumber, customerDetails);

  useEffect(() => {
    if (openServiceTicketDialog) {
      dispatch(setOpenSelectReasons(true));
      setFormData(initialFormData);
      setFormErrors(initialFormErrors);
    } else {
      resetAllStates();
    }
  }, [openServiceTicketDialog, formattedPhone]);

  const [availableSlotsForTT, { loading: getAvailableSlotsLoading, data: availableSlots }] =
    useLazyQuery(GET_AVAILABLE_SLOTS_FOR_TT, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data?.availableSlotsForTT?.appointmentSlots.length > 0) {
          setShowAppointment(true);
          setavailableSlotsError(false);
        } else {
          setShowAppointment(true);
          setavailableSlotsError(true);
        }
      },
      onError: (error: any) => {
        setShowAppointment(true);
        setavailableSlotsError(true);
      },
    });

  const [createServiceTicket, { loading: createTicketLoading }] = useMutation(
    CREATE_SERVICE_TICKET,
    {
      onCompleted: (data) => {
        setIsSubmitting(false);
        setShowContactInfo(false);
        dispatch(setOpenSelectReasons(false));
        const error = data?.createServiceTicketByAccountNumber?.error;
        if (error) {
          dispatch(setCreateServiceTicketFail(true));
        } else {
          dispatch(setCreateServiceTicketSuccess(true));
        }
        getTroubleTicketStatus();
      },
      onError: () => {
        setIsSubmitting(false);
        dispatch(setCreateServiceTicketFail(true));
        dispatch(setOpenSelectReasons(false));
      },
    }
  );

  const finalReason = selectReason?.isOther ? otherReason : selectReason?.reason ?? "";
  const handleServiceTicket = async () => {
    try {
      setIsSubmitting(true);

      const serviceTicketInput = {
        customerName: givenNameFamilyName?.givenName,
        primaryNumber: defaultFormatPhone(formData.phoneNumber),
        customerNotes: otherReason,
        troubleTicketReason: selectReason?.reason,
        usi: ndsObj?.usi,
        addressId: detailsForTTAvailableSlots?.addressId,
        territoryId: detailsForTTAvailableSlots?.territoryId,
        accountUUID: detailsForTTAvailableSlots?.accountUuid,
        btn: detailsForTTAvailableSlots?.btn,
        ...(selectReason?.dispatchOrder && {
          firstOfferedDate:
            availableSlots?.availableSlotsForTT?.appointmentSlots[0]?.startDate ?? "",
          firstSelectedDate: selectedAppointment?.startDate ?? "",
          startDate: selectedAppointment?.startDate ?? "",
          endDate: selectedAppointment?.endDate,
          units: selectedAppointment?.workUnitsQuantity,
        }),
      };

      const response = await createServiceTicket({
        variables: {
          accountNumber,
          isNds: ndsObj?.isNDS,
          serviceTicketInput,
        },
      });

      if (response?.data) {
        setShowContactInfo(false);
        const { error } = response.data.createServiceTicketByAccountNumber || {};
        if (!error) {
          setCreateServiceTicketSuccess(true);
        } else {
          setCreateServiceTicketFail(true);
        }
      }
    } catch (err) {
      setIsSubmitting(false);
      dispatch(setCreateServiceTicketFail(true));
    }
  };

  useEffect(() => {
    if (shouldFetchSlots && selectReason.reason) {
      availableSlotsForTT({
        variables: {
          accountNumber,
          isNds: ndsObj?.isNDS,
          territoryId: detailsForTTAvailableSlots?.territoryId,
          reasonText: selectReason?.reason,
        },
      });

      setShouldFetchSlots(false);
    }
  }, [
    shouldFetchSlots,
    accountNumber,
    ndsObj?.isNDS,
    detailsForTTAvailableSlots?.territoryId,
    selectReason?.reason,
    availableSlotsForTT,
  ]);

  const resetAllStates = () => {
    setSelectReason({});
    setShowContactInfo(false);
    setShowAppointment(false);
    setShouldFetchSlots(false);
    setSelectedTimeSlot(null);
    setOtherReason("");
    setOtherReasonError("");
    setOpenOtherReason(false);
    setIsSubmitting(false);
    setFormData(initialFormData);
    setFormErrors(initialFormErrors);
  };

  const handleClose = () => {
    dispatch(setOpenServiceTicketDialog(false));
    resetAllStates();
  };

  const handleReasonNext = () => {
    if (selectReason.reason) {
      if (selectReason?.isOther) {
        setOpenOtherReason(true);
      } else {
        setShowContactInfo(true);
      }
    }
  };

  const closeSuccessbox = () => {
    dispatch(setCreateServiceTicketSuccess(false));
    dispatch(setOpenServiceTicketDialog(false));
    navigate("/home");
  };

  const handleContactInfoNext = () => {
    if (Object.values(formErrors).some((error) => error !== "")) {
      return;
    }

    if (selectReason?.dispatchOrder) {
      setShouldFetchSlots(true);
    } else {
      handleServiceTicket();
    }
  };

  const handleSelectAppointment = () => {
    if (selectedTimeSlot) {
      handleServiceTicket();
    }
  };

  const handleBackToReason = () => {
    setShowContactInfo(false);
    if (selectReason?.isOther) {
      setOpenOtherReason(true);
    }
  };

  const handleBackToContactInfo = () => {
    setShowAppointment(false);
    setShowContactInfo(true);
  };

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setOtherReason(newValue);

      if (newValue.trim() && otherReasonError) {
        setOtherReasonError("");
      }
    },
    [otherReasonError]
  );

  const handleNextFromOther = () => {
    if (!otherReason.trim()) {
      setOtherReasonError("Please provide a reason");
      return;
    }

    setSelectReason((prev) => ({
      ...prev,
      reason: "Other",
    }));

    setOtherReasonError("");
    setOpenOtherReason(false);
    setShowContactInfo(true);
  };

  const handleBackFromOther = () => {
    setOpenOtherReason(false);
    setOtherReasonError("");
    setOtherReason("");
  };

  const dialogHeading = () => {
    if (showAppointment) {
      return "We may need to schedule a repair appointment";
    } else if (showContactInfo) {
      return CREATE_SERVICE_TICKET_HEADINGS.secondary;
    } else if (openOtherReason) {
      return "Please specify what's wrong";
    } else {
      return CREATE_SERVICE_TICKET_HEADINGS.primary;
    }
  };

  const heading = dialogHeading();

  const handleBack = () => {
    if (showAppointment) {
      handleBackToContactInfo();
    } else if (showContactInfo) {
      handleBackToReason();
    } else if (openOtherReason) {
      handleBackFromOther();
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (openOutageBanner || existingTicket || createServiceTicketFail) {
      dispatch(setOpenServiceTicketDialog(false));
    }
  }, [openOutageBanner, existingTicket, createServiceTicketFail, dispatch]);

  const handleRefresh = () => {
    if (AvailableSlotsError) {
      availableSlotsForTT({
        variables: {
          accountNumber,
          isNds: ndsObj?.isNDS,
          territoryId: detailsForTTAvailableSlots?.territoryId,
          reasonText: selectReason?.reason,
        },
      });
    }

    if (reasonsForTTError || getTTDetailsError) {
      getReasonCodesForTT();
      getDetailsForAvailableSlots();
    }
  };

  const ServiceTicketErrorMessage = ({ messageLine1, messageLine2 }: any) => {
    return (
      <CenterStack my={4} mt={8}>
        <Typography textAlign={"center"} fontSize={{ xs: "16px", md: "18px" }} fontWeight={600}>
          {messageLine1}
        </Typography>
        <Typography mt={3} fontSize={{ xs: "16px", md: "18px" }}>
          {messageLine2}
        </Typography>
        <Stack direction={"row"} gap={0.5} flexWrap={"wrap"}>
          <Typography fontSize={"16px"}>If the issue persist,</Typography>
          <ContactSupport fontSize={"16px"} text="CHAT WITH AN AGENT" fontWeight={600} />
          <Typography fontSize={"16px"}>or</Typography>
          <Typography fontSize={"16px"}>Call</Typography>
          <StyledA sx={{ fontWeight: 600 }} href="tel:1-866-699-4759">
            1-866-MY-ZIPLY
          </StyledA>
          <Typography fontSize={"16px"}>for future assistance</Typography>
        </Stack>
        <Stack justifyContent="center" alignItems="center" mb={3} mt={6}>
          <Button type="payment" title="REFRESH" onClick={handleRefresh} />
        </Stack>
      </CenterStack>
    );
  };

  const renderDialogContent = () => {
    if (showAppointment && !createServiceTicketSuccess) {
      return (
        <>
          {AvailableSlotsError ? (
            <ServiceTicketErrorMessage
              messageLine1={"We are unable to retrieve available time slots at the moment."}
              messageLine2={
                "Kindly wait for a few moments as we attempt to retrieve the available time slots for you."
              }
            />
          ) : (
            <>
              <RepairAppointmentDialog
                open={showAppointment}
                availableSlotsForTT={availableSlots?.availableSlotsForTT}
                loading={getAvailableSlotsLoading}
                onBack={handleBackToContactInfo}
                onClose={handleClose}
                formData={formData}
                selectedReason={selectReason}
                setSelectedTimeSlot={setSelectedTimeSlot}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedAppointment={setSelectedAppointment}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
              />

              <Stack justifyContent="center" alignItems="center" mb={3}>
                <Button
                  type="payment"
                  title="Next"
                  onClick={handleSelectAppointment}
                  disabled={!selectedTimeSlot || isSubmitting}
                />
              </Stack>
            </>
          )}
        </>
      );
    } else if (showContactInfo) {
      return (
        <>
          <ContactInfoVerification
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            selectedReason={selectReason}
            otherReason={otherReason}
          />

          <Stack justifyContent="center" alignItems="center" mb={3}>
            <Button
              type="payment"
              title="Next"
              onClick={handleContactInfoNext}
              disabled={
                Object.values(formErrors).some((error) => error !== "") ||
                shouldFetchSlots ||
                getAvailableSlotsLoading ||
                isSubmitting
              }
            />
          </Stack>
        </>
      );
    } else if (openOtherReason) {
      return (
        <Stack spacing={3} width="100%" px={2}>
          <Box m={2}>
            <Stack mb={12} spacing={1}>
              <ProfileInput
                name="otherReason"
                placeholder="Type here..."
                type="text"
                fullWidth
                value={otherReason}
                onChange={handleReasonChange}
                error={Boolean(otherReasonError)}
                multiline
                rows={4}
                aria-invalid={Boolean(otherReasonError)}
                aria-describedby={otherReasonError ? "other-reason-error" : undefined}
              />

              {otherReasonError && (
                <ErrorMessage id="other-reason-error">{otherReasonError}</ErrorMessage>
              )}
            </Stack>
          </Box>
          <Stack justifyContent="center" alignItems="center" mb={3}>
            <Button
              type="payment"
              title="Submit"
              onClick={handleNextFromOther}
              disabled={!otherReason.trim()}
            />
          </Stack>
        </Stack>
      );
    } else if (openSelectReasons) {
      return (
        <Grid container direction="row" justifyContent="space-around" width="100%" mb={2}>
          {reasonsForTTError || getTTDetailsError ? (
            <ServiceTicketErrorMessage
              messageLine1={" We are currently unable to retrieve the service ticket flow"}
              messageLine2={"  Please wait a moment and try again."}
            />
          ) : (
            <>
              {[...reasonsForTT]
                ?.sort((a, b) => (a?.reason === "Other" ? 1 : b?.reason === "Other" ? -1 : 0))
                ?.map((reasonObj: any) => (
                  <Grid item xs={12} md={5.5} key={reasonObj?.reason}>
                    <CreateServiceticketStyledCard
                      sx={{
                        margin: { xs: 2, md: "0.6rem 0" },
                        fontSize: { xs: "14px", md: "16px" },
                        border:
                          selectReason?.reason === reasonObj?.reason
                            ? `2px solid ${Theme.palette.secondary.main}`
                            : "",
                      }}
                      onClick={() => {
                        setSelectReason({
                          reason: reasonObj?.reason,
                          dispatchOrder: reasonObj?.dispatch,
                          isOther: reasonObj?.reason === "Other",
                        });
                      }}>
                      {reasonObj?.reason}
                    </CreateServiceticketStyledCard>
                  </Grid>
                ))}

              <Stack justifyContent="center" alignItems="center" width="100%" mt={3}>
                <Button
                  type="payment"
                  title="Next"
                  onClick={handleReasonNext}
                  disabled={!selectReason.reason}
                />
              </Stack>
            </>
          )}
        </Grid>
      );
    }
  };

  return (
    <>
      <StyledDialog
        open={openServiceTicketDialog && openSelectReasons}
        maxWidth="md"
        fullWidth
        fullScreen={useMediaQuery(Theme.breakpoints.down("md"))}
        onClose={handleClose}
        aria-labelledby="service-ticket-dialog-title">
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={handleClose}
          aria-label="close">
          <CloseIcon />
        </IconButton>
        <Stack direction="row" alignItems="center">
          <Box onClick={handleBack} sx={{ cursor: "pointer", pt: 1 }}>
            <ArrowBackIosIcon color="secondary" />
          </Box>
          <CardHeading
            id="service-ticket-dialog-title"
            sx={{ fontSize: { xs: "14px", md: "18px" } }}>
            {heading}
          </CardHeading>
        </Stack>

        <DialogContent sx={{ maxHeight: "70vh", overflowY: "auto" }}>
          {renderDialogContent()}
        </DialogContent>
        {(getAvailableSlotsLoading ||
          createTicketLoading ||
          reasonCodesForTTLoading ||
          getAvailableSlotsDetailsLoading) && <LoadingSpinner />}
      </StyledDialog>

      <SuccessDialogBox
        open={createServiceTicketSuccess}
        onClose={closeSuccessbox}
        heading={CREATE_SERVICE_TICKET_HEADINGS.success}
        defaultDetails={[
          { label: "Primary contact number", value: formData.phoneNumber },
          ...(selectedDate && selectedTimeSlot
            ? [
                {
                  label: "Preferred appointment date and time",
                  value: `${moment(selectedDate).format("MMM D")}, ${selectedTimeSlot}`,
                },
              ]
            : []),
          { label: "Service address", value: addSpaceAfterComma(serviceAddress) },
          {
            label: "Reason",
            value: finalReason || "Not specified",
          },
        ]}
      />
    </>
  );
}

export default CreateServiceTicketNew;
