import React, { useEffect, useRef, useState } from "react";
import { EndPoints } from "./Routes/Routes";
import "./App.css";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { auth } from "./Config/firebase";
import { Theme } from "./Styles/GlobalTheme";
import { ApolloProvider } from "@apollo/client";
import { client } from "./Services/api";
import { ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userInfo } from "./Redux/Slices/UserSlice";
import { ForgotPassword } from "./Pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "./Pages/ForgotPassword/ResetPassword";
import Login from "./Pages/Login/Login";
import { SignIn } from "./Pages/Signin/SignIn";
import Payment from "./Pages/Payment/Payment";
import { adminInfo } from "./Redux/Slices/IsAdminSlice";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import { Helmet } from "react-helmet-async";
import { getSessionID, setCookie } from "./Services/helper";
import { clarity } from "react-microsoft-clarity";
import { pageTitles } from "./Constants/constants";
import { getFlags } from "./Services/helper";
import { getCleanRedirectURL } from "./Services/helper";
import { setDeeplinkAccNum } from "./Redux/Slices/CustomerInfoSlice";

// import { init as initApm } from "@elastic/apm-rum";
const unAuthorizedRoutes = [
  "/login",
  "/register",
  "/login/forgot-password",
  "/payment",
  "/login/reset-password",
];

function App() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const isChatLoaded = useRef(null);
  const [user, setUser] = React.useState(undefined);
  const userRef = useRef(user);
  const [initChat, setInitChat] = React.useState(false);
  const dispatch = useDispatch();
  const [isAdminUser, setIsAdminUser] = React.useState();
  const customerDetails = useSelector((state) => state?.customerInfoDetails);
  const [customer, setCustomer] = useState(customerDetails);
  const [chatinit, setChatinit] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const unprotectedRoutes = [
    "/myaccount/payment",
    "/myaccount/login",
    "/myaccount/admin/login",
    "/myaccount/register",
    "/myaccount/login/forgot-password",
    "/myaccount/payment/",
    "/myaccount/login/",
    "/myaccount/register/",
    "/myaccount/login/reset-password",
    "/myaccount/login/reset-password/",
    "/myaccount/login/forgot-password/",
    "/myaccount/adminscreen/",
  ];
  const envFlags = useSelector((state) => state?.userInfo?.envFlags);
  const CLARITY = getFlags("CLARITY", envFlags);
  const redirectURL = localStorage.getItem("redirectURL");

  // const apm = initApm({
  //   serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME, // Name of your service (shown in Kibana)
  //   serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL, // URL of your Elastic APM server
  //   environment: process.env.REACT_APP_ELASTIC_APM_SERVER_URL, // Set environment (production, development, etc.)
  // });

  const getUserToken = (user) => {
    if (user) {
      const { accessToken, email } = user;
      const userDetails = {
        email: email,
        userToken: accessToken,
      };
      dispatch(userInfo(userDetails));
    }
  };

  useEffect(() => {
    const checkLastLoginTimestamp = () => {
      const lastLogin = localStorage.getItem("lastLogin");

      if (lastLogin) {
        const lastLoginDate = new Date(lastLogin);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - lastLoginDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 30 && !sessionStorage.getItem("signedOutDueToInactivity")) {
          sessionStorage.setItem("signedOutDueToInactivity", "true");
          auth.signOut();
          navigate("/login");
          return true; // Indicate that we signed out the user
        }
      }
      return false; // Did not sign out
    };
    const didSignOut = checkLastLoginTimestamp();

    if (!didSignOut && auth.currentUser) {
      localStorage.setItem("lastLogin", new Date().toString());
    }
  }, []);

  const getAuthStateChanged = async (user) => {
    if (user) {
      const { accessToken, email } = user;
      const redirectURL = localStorage.getItem("redirectURL");
      const cleanedRedirectURL = redirectURL ? getCleanRedirectURL(redirectURL) : "";
      const lastLogin = localStorage.getItem("lastLogin");

      localStorage.setItem("lastLogin", new Date().toString());
      if (false) {
        setUser(user);
        alert("success");
        navigate("/adminscreen");
      } else {
        setUser(user);
        if (
          window.location.pathname === "/myaccount" ||
          window.location.pathname === "/myaccount/"
        ) {
          if (cleanedRedirectURL && !cleanedRedirectURL.includes("/login")) {
            navigate(cleanedRedirectURL);
          } else {
            navigate("/checking-account");
          }
        }
      }
    } else if (unprotectedRoutes.includes(window.location.pathname)) {
      setUser(null);
      const navigateURL =
        window.location.pathname.replace("/myaccount/", "/") + window.location.search;
      navigate(navigateURL);
    } else {
      setUser(null);
      navigate("/login");
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, getAuthStateChanged);
    onIdTokenChanged(auth, getUserToken);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, getAuthStateChanged);
    onIdTokenChanged(auth, getUserToken);
  }, []);

  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_KEY);
    const sessionID = getSessionID();
    if (clarity.hasStarted()) {
      window.clarity("set", "customSessionId", sessionID);
      clarity.identify(sessionID, { userProperty: "value" });
    }
    // apm.startTransaction("user-login", "interaction");
  }, []);

  useEffect(() => {
    if (query.get("initiateChat")) {
      setChatinit(true);
    }
  }, [query.get("initiateChat")]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const pageChageEvent = new CustomEvent("pageChange", { detail: { pathname } });
    document.dispatchEvent(pageChageEvent);
  }, [pathname]);
  const isChatAvailable = (user, details) => {
    if (window.triggerOmniChat) {
      if (user) {
        if (Object.keys(details.emailContact).length > 0) {
          window.triggerOmniChat("D582223DCFE61");
          setInitChat(true);
        }
      } else if (user === null) {
        unAuthorizedRoutes.includes(pathname) &&
          window.triggerOmniChat &&
          window.triggerOmniChat("D582223DCFE61");
        setInitChat(true);
      }
    }
  };

  useEffect(() => {
    if (chatinit) {
      isChatLoaded.current = setInterval(() => {
        isChatAvailable(user, customer);
      }, 1000);
    }
    return () => clearInterval(isChatLoaded.current);
  }, [user, customer, chatinit]);

  useEffect(() => {
    setCustomer(customerDetails);
  }, [customerDetails]);
  useEffect(() => {
    if (document.getElementById("QSIFeedbackButton-btn")) {
      document.getElementById("QSIFeedbackButton-btn").style.display = pathname.includes("payment")
        ? "none"
        : "block";
    }
  }, [pathname]);

  useEffect(() => {
    if (initChat) {
      clearInterval(isChatLoaded.current);
    }
  }, [initChat]);

  useEffect(() => {
    const getPageTitle = () => {
      if (pathname) {
        const path = Object.keys(pageTitles).find((key) => pathname.includes(key));
        if (path) {
          return `My Account ${pageTitles[path]} | ZiplyFiber`;
        }
      }
      return "My Account | ZiplyFiber";
    };

    const title = getPageTitle();
    setPageTitle(title);
  }, [pathname]);

  const [currentPath, setCurrentPath] = useState(() => {
    const path = window.location.pathname;
    const search = window.location.search;
    if (path === "/myaccount" || path === "/myaccount/") {
      return "/login";
    }

    return path + search;
  });

  useEffect(() => {
    dispatch(setDeeplinkAccNum(query.get("account_number") ?? ""));
    localStorage.setItem("redirectURL", currentPath);
  }, [currentPath]);

  useEffect(() => {
    if (user && query.get("account_number")) {
      navigate("/checking-account");
    }
  }, [user]);

  useEffect(() => {
    const handlePathChange = () => {
      setCurrentPath(window.location.pathname + window.location.search);
    };
    window.addEventListener("popstate", handlePathChange);
    return () => {
      window.removeEventListener("popstate", handlePathChange);
    };
  }, []);

  useEffect(() => {
    const tabCounterKey = "openTabCount";
    const isCheckedKeepMeLoggedIn = localStorage.getItem("KEEP_ME_LOGGED_IN") === "false";

    const incrementTabCounter = () => {
      const count = parseInt(localStorage.getItem(tabCounterKey) || "0", 10);
      localStorage.setItem(tabCounterKey, (count + 1).toString());
    };

    const decrementTabCounter = () => {
      const count = parseInt(localStorage.getItem(tabCounterKey) || "0", 10);
      const newCount = count > 0 ? count - 1 : 0;
      localStorage.setItem(tabCounterKey, newCount.toString());

      if (newCount === 0 && isCheckedKeepMeLoggedIn) {
        auth.signOut();
        localStorage.clear();
      }
    };

    incrementTabCounter();
    window.addEventListener("beforeunload", decrementTabCounter);

    return () => {
      decrementTabCounter();
      window.removeEventListener("beforeunload", decrementTabCounter);
    };
  }, []);

  return (
    <>
      <ApolloProvider client={client}>
        {/* dynamic page titles using react-helmet-async  */}
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          {user?.uid ? (
            <EndPoints userDetails={user} isAdmin={isAdminUser} />
          ) : (
            <Routes>
              <Route path="/payment" element={<Payment />} />
              <Route path="/" element={<Navigate to={"/login"} />} />
              <Route path="/login" element={<Login setUser={setUser} />} />
              <Route path="/register" element={<SignIn />} />
              <Route path="/login/reset-password" element={<ResetPassword />} />
              <Route path="/login/forgot-password" element={<ForgotPassword />} />
              <Route path="/admin/login" element={<ErrorPage user={user} />} />
            </Routes>
          )}
        </ThemeProvider>
      </ApolloProvider>

      {/* <app-bold-chat-widget
        name={`${customerDetails?.givenNameFamilyName?.givenName} ${customerDetails?.givenNameFamilyName?.familyName}`}
        email={`${customerDetails?.emailContact?.emailAddress}`}
        phone={`${customerDetails?.telephoneContact?.telephoneNumber}`}></app-bold-chat-widget> */}
    </>
  );
}

export default App;
